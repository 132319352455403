<div *ngIf="loading; else loadedContent">
    <!-- Indicador de carga aquí -->
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
  </div>

<ng-template #loadedContent>
    <div id="miCarrusel" class="carousel slide" data-ride="carousel">
        <mat-button mat-dialog-close><mat-icon>close</mat-icon></mat-button>
        <ol class="carousel-indicators">
          <!-- <li [class.active]="currentIndex === 0" (click)="changeSlide(0)"></li>
          <li [class.active]="currentIndex === 1" (click)="changeSlide(1)"></li>
          <li [class.active]="currentIndex === 2" (click)="changeSlide(2)"></li> -->
          <li [class.active]="currentIndex === i" *ngFor="let img of images; let i = index" (click)="changeSlide(i)"></li>
        </ol>
      
       
        <div class="carousel-inner" >
          <!-- <div class="carousel-item" [class.active]="currentIndex === 0">
            <img [src]="images" alt="Segunda Imagen">
          </div> -->
          <div class="carousel-item" [class.active]="currentIndex === i" *ngFor="let img of images; let i = index">
            <img [src]="img" alt="Imagen">
          </div>
        </div>
      
        
        <a class="carousel-control-prev" (click)="prevSlide()">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Anterior</span>
        </a>
        <a class="carousel-control-next" (click)="nextSlide()">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Siguiente</span>
        </a>
      </div>
</ng-template>

  
<!-- <h1>Carrucel pa</h1>
<div *ngIf="images">
    <div  *ngFor="let img of images"  >
        <img [src]="img" alt="img">
     </div>
</div> -->
