import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentRoutes } from './content.routing';

import { HomeComponent } from './home/home.component';
import { AccountComponent } from './account/account.component';
import { LoginComponent } from './login/login.component';
import { AllProductsComponent } from './all-products/all-products.component';
import { CustomersComponent } from './customers/customers.component';

import { SharedModule } from '../shared/shared.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input'; 
import { MatCardModule } from '@angular/material/card'; 
import { MatDividerModule } from '@angular/material/divider'; 
import { NewOrderComponent } from './new-order/new-order.component';
import { ProductDetailComponent } from '../content/new-order/new-order.component';
import { NgSelect2Module } from 'ng-select2';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { AllOrdersComponent, OrderDetailComponent } from './all-orders/all-orders.component';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip'; 
import { MatNativeDateModule, MatRippleModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
import { CurrencyCustomPipe } from '../shared/pipes/currency-custom.pipe';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ImageCarouselComponent } from '../image-carousel/image-carousel.component';


@NgModule({
  declarations: [
    HomeComponent,
    AccountComponent,
    LoginComponent,
    AllProductsComponent,
    CustomersComponent,
    NewOrderComponent,
    ProductDetailComponent,
    OrderDetailComponent,
    AllOrdersComponent,
    CurrencyCustomPipe,
    ImageCarouselComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ContentRoutes,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatDividerModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    NgSelect2Module,
    MatExpansionModule,
    MatDialogModule,
    MatTabsModule,
    MatTableModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatAutocompleteModule
  ],
  providers: [NewOrderComponent,
    AllOrdersComponent, DatePipe,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }],
  exports: [MatDatepickerModule]
  
})
export class ContentModule { }
