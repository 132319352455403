
<div class="container-fluid">
    <div class="page-title">
      <div class="row">
        <div class="col-6">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/customers/']"><i class="fa fa-user"></i></a></li>
            <li class="breadcrumb-item active"> Clientes</li>
          </ol>
        </div>
      </div>
    </div>
</div>

<div class="container-fluid">
    <div class="col-sm-12">
        <div class="card">
            <div class="card-header">
                <div class="col-sm-6" >             
                    
               </div>
            </div>
            
           <div class="card-body">
             <div *ngIf="customers" class="">
               <table class="table table-hover mbn" id="customers-index">
                 <thead>
                   <tr>
                    <th>ID Cliente</th>
                     <th>Nombre</th>
                     <th>Acciones</th>
                   </tr>
                 </thead>
                 <tbody *ngIf="isDataLoaded == 1">
                  <tr>
                    <td colspan="3" align="center"><mat-spinner [diameter]="50"></mat-spinner></td>
                  </tr>
                </tbody>
                 <tbody *ngIf="isDataLoaded == 2">
                   <tr *ngFor="let column of customers" id="tr_customer_{{column.IDCliente}}">
                    <td>{{column.IDCliente}}</td>
                     <td>{{column.Nombre}}</td>
                     <td>
                      <a class="" [routerLink]="['/new-order/',column.IDCliente]" >
                        <i class="icon-pencil-alt"></i> Crear pedido
                      </a>&nbsp;&nbsp;&nbsp;
                 
                    </td>
                 
                   </tr>        
                 </tbody>
               </table>
             </div>
           </div>
        </div>
    </div>
</div>
