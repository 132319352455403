import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TimerService {
  private stopTimerSubject = new BehaviorSubject<boolean>(false);

  // Observable que otros componentes pueden suscribirse para recibir eventos
  stopTimer$ = this.stopTimerSubject.asObservable();

  // Método para disparar el evento stopTimer
  triggerStopTimer() {
    this.stopTimerSubject.next(true); // Emitir el evento
  }
}
