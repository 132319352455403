import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  static user: any;
  static customer: any;
  static customers: any = [];
  static customer_detail: any;

  constructor(
    private http: HttpClient
  ) 
  { 
    
  }

  public login(usuario: any, password: any):Observable<any> {
    var headers = new HttpHeaders();
    headers.append('Content-Type', "application/json");
 
    // console.log('loguiandote')
    return this.http.post(`${environment.url_api}/auth/login`, { "usuario":usuario, "password":password },{ headers:headers })
  }

  public logout(token:string):Observable<any> {
    var headers = new HttpHeaders({ 'Authorization': token, 'Content-Type': 'application/json' });
   
    return this.http.get(`${environment.url_api}/auth/logout`, { headers:headers } )
  }

  public isLoggedIn(): boolean {
    const jwt = localStorage.getItem('jwt');
    const user = JSON.parse(localStorage.getItem('user') || '[]');
  
    if(jwt){
      LoginService.set_user(user);
      return true;
    }
    else{
      return false;
    }
    
  }

  public user_detail(id_tipo: any):Observable<any> {
    const formData = new FormData()
    formData.append("id_tipo", id_tipo);

    return this.http.post(`http://localhost/sirvete/login/get_user_detail`, formData )
  }


  public static set_user(user: any): void{
    LoginService.user = user;
  }

  public static get_user(): any{
    return LoginService.user;
  }

  public static set_customer(customer: any): void{
    LoginService.customer = customer;
  }

  public static get_customer(): any{
    return LoginService.customer;
  }

  // public get_precio_minimo(sede, transportista):any{
  //   var headers = new HttpHeaders({ 'Content-Type': 'application/json' });
   
  //   return this.http.get(`${environment.url_api}/precio-minimo`, { headers:headers } )
  // }

  public static set_customer_detail(customer: any): void{
    LoginService.customer_detail = customer;
  }

  public static get_customer_detail(): any{
    return LoginService.customer_detail;
  }


  public static set_all_customers(customers: any): void{
    LoginService.customers = customers;
  }

  public static get_all_customers(): any{
    return LoginService.customers;
  }

}
