import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ProductsService } from 'src/app/services/products.service';

@Component({
  selector: 'app-all-products',
  templateUrl: './all-products.component.html',
  styleUrls: ['./all-products.component.css']
})
export class AllProductsComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  public products: any[] = [];
  public product: any[] = [];

  constructor(
    private productsService:ProductsService,
    private router: Router
  ) 
  { }

  ngOnInit(): void {
    this.get_products()
  }

  get_products(){
    let cust_data = {
      "search": "7-A",
      "searchOriginal": "7-A",
      "idOrganizacionVentas": "1010300",
      "idArea":"1010000",
      "idMoneda":"MXN",
      "idClasificacion":"",
      "idMarca":"82",
      "idFamilia":"",
      "idProducto":"",
      "idCliente":"119",
      "idJerarquiaCliente":"SR",
      "idCanalDistribucion":"01",
      "paginaInicio":"0",
      "paginaFin":"15"
    }
    this.productsService.getAll(cust_data).subscribe(data => {
      this.products = data;
      window.setTimeout( function (){ $('#products-all').DataTable(
        {
          pagingType: 'full_numbers',
          language: {
            "processing": "Procesando",
            "lengthMenu": "Mostrar _MENU_ registros",
            "emptyTable": "Ningún dato disponible en esta tabla",
            "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
            "infoEmpty": "Mostrando 0 registros del 0 de entradas",
            "infoFiltered": "(filtrado de un total de _MAX_ registros)",
            "search": "Buscar",
            "infoPostFix": "",
            "url": "",
            "zeroRecords": "No se encontraron resultados",
            "loadingRecords": "Cargando...",
            "paginate":{
              "first": "Primero",
              "last": "Último",
              "next": "Siguiente",
              "previous": "Anterior"
            },
            "aria": {
              "sortAscending": ": Activar para ordenar la columna de manera ascendente",
              "sortDescending": ": Activar para ordenar la columna de manera descendente"
            }
    
            
            
          }
        }
      )}, 1000);
      //this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    });
  }

  delete_product(id: any){
    if(confirm("Esta seguro que desea eliminar")) {
      this.productsService.delete(id).subscribe(
        data => { this.product = data;
                  this.router.navigate(['./categories/']);
                  //document.querySelector('#tr_category_'+id).remove();
                },
        error => {alert("Ha ocurrido un error  "+error["message"])}
      );
    }
  }

}
