<!-- Start: Main -->
<div id="main" >

  <!-- Start: Content-Wrapper -->
  <section id="content_wrapper">

    <!-- Begin: Content -->
    <section id="content">
      <div class='loginDiv'>    
        <form name="projectForm" action='/sirvete/login/procesa' method='post' [formGroup]="LoginForm">
          <mat-card-title>
            <img src="assets/img/Logotipo Simplificado Azul.png" title="Dapesa" class="img-responsive w250">
          </mat-card-title> 
  
          
          <mat-form-field appearance="standard">
            <!-- <mat-icon>person</mat-icon> -->
            <mat-label>Usuario</mat-label>
            <input type="text" matInput formControlName="user"
                   placeholder="Ex. Usuario123" required>
                   <mat-icon matPrefix>person</mat-icon>
              <!-- <div *ngIf="LoginForm.controls.user.touched && LoginForm.controls.user.invalid">
                <mat-error *ngIf="LoginForm.controls.user.errors?.required">Requerido</mat-error>
                <mat-error *ngIf="LoginForm.controls.user.errors?.minlength">Minimo 8 caracteres</mat-error>
              </div> -->
          </mat-form-field>
      
          <mat-form-field class="example-full-width">
            <!-- <mat-icon>lock</mat-icon> -->
            <mat-label>Password</mat-label>
            <input [type]="show ? 'password' : 'text'" matInput formControlName="password" placeholder="Password" required>
            <!-- <div *ngIf="LoginForm.controls.password.touched && LoginForm.controls.password.invalid">
              <mat-error *ngIf="LoginForm.controls.password.errors?.required">Requerido</mat-error>
              <mat-error *ngIf="LoginForm.controls.password.errors?.minlength">Minimo 8 caracteres</mat-error>
            </div> -->
            <mat-icon matPrefix>lock</mat-icon>
            <mat-icon matSuffix (click)="togglePasswordVisibility()">
              {{ show ? 'visibility_off' : 'visibility' }}
            </mat-icon>
            <!-- <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
            <div class="show-hide" (click)="showPassword()"><span class="hide"></span></div> -->
    
          </mat-form-field>
          <br /> <br />
          <div layout="row" layout-xs="column" layout-align="center center">
            <button class="button-login" mat-raised-button color="primary" (click)="login()">INICIAR SESIÓN</button>
            <div flex="flex"></div>
          </div>
          <!-- <br /> -->
          <hr>
          <div class="help-text">
            <span>Correo electrónico de soporte</span> 
            <span><b>soporte_sap@dapesa.com.mx</b></span>
          </div>
          
        </form>
      </div>
    </section>
    <!-- End: Content -->

  </section>
  <!-- End: Content-Wrapper -->

</div>
<!-- End: Main -->
