
<div class="container-fluid">
    <div class="page-title">
      <div class="row">
        <div class="col-6">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="['/all-orders/']"><i class="fa fa-list"></i></a></li>
            <li class="breadcrumb-item active"> Pedidos</li>
          </ol>
        </div>
      </div>
    </div>
</div>


<div class="container-fluid">
    <div class="col-sm-12">
        <div class="card">
            <div class="card-header">
              <div class="col-md-6 container-select col-sm-12" >
                <!-- <ng-select2 [(value)]="customer_id" id="select2-customer" class="js-example-placeholder-multiple" [data]="customers" [placeholder]="'Seleccione una opción...'" [allowClear]="true" (valueChanged)="get_customer($event)"  width="100%" height="10px;" style="width: 100%;"></ng-select2>  -->
                <!-- <select [(value)]="customer_id" id="select2-customer" class="js-example-placeholder-multiple" [data]="customers"  (change)="get_customer($event)" width="100%" height="10px;" style="width: 100%;"></select>  -->
                <!-- <select [(ngModel)]="selectedOption">
                  <option *ngFor="let customer of customers" [value]="customer_id">{{ option }}</option>
                </select> -->
                <form class="example-form">
                  <mat-form-field class="example-full-width">
                    <mat-label>Cliente</mat-label>
                    <input type="Cliente"
                           placeholder="Seleccionar cliente"
                           aria-label="Cliente"
                           matInput
                           [formControl]="customer_select"
                           [matAutocomplete]="auto">
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayCustomerFn" (optionSelected)="get_customer($event)">
                      <mat-option *ngFor="let custom of filteredOptions | async" [value]="custom">
                        {{custom.text}}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </form>
                <!-- <mat-form-field >
                  <mat-label>Selecciona Cliente</mat-label>
                  <input type="text" matInput [formControl]="searchControl" [matAutocomplete]="auto">
                  <mat-autocomplete #auto (optionSelected)="get_customer($event)">
                    <mat-option *ngFor="let custom of customers | async" [value]="custom.id">
                      {{custom.text}}
                    </mat-option>
                  </mat-autocomplete>

                  <mat-select (selectionChange)="get_customer($event)">
                    <mat-option *ngFor="let custom of customers" [value]="custom.id">
                      {{custom.text}}
                    </mat-option>
                  </mat-select>
                </mat-form-field> -->
              </div>
              <div class="col-md-3  col-sm-12">
                <!-- <mat-form-field color="accent" appearance="standard">
                  <mat-label>Fecha inicio</mat-label>
                  <input matInput [value]="date_default" [matDatepicker]="picker1"  (dateChange)="get_date_start('change', $event)">
                  <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                  <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field> -->
                <mat-form-field>
                  <mat-label>Fecha Inicio:</mat-label>
                  <input matInput [value]="date_default"  [matDatepicker]="picker1" (dateChange)="get_date_start('change', $event)">
                  <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
                  <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                  <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col-md-3  col-sm-12">
                <!-- <mat-form-field color="accent" appearance="standard">
                  <mat-label>Fecha fin</mat-label>
                  <input matInput [value]="date_default" [matDatepicker]="picker2"  (dateChange)="get_date_end('change', $event)">
                  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                  <mat-datepicker #picker2 color="primary"></mat-datepicker>
                </mat-form-field> -->
                <mat-form-field>
                  <mat-label>Fecha Fin:</mat-label>
                  <input matInput [value]="date_default"  [matDatepicker]="picker" (dateChange)="get_date_end('change', $event)">
                  <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
                  <mat-datepicker-toggle matSuffix [for]="picker" ></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
           <div class="card-body">
             <div *ngIf="orders" class="">
              <mat-form-field>
                <input
                  matInput
                  (keyup)="applyFilter($event.target)"
                  placeholder="Buscar"
                />
              </mat-form-field>
              <table mat-table [dataSource]="orders" class="mat-elevation-z8">

                <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->
              
                <!-- Position Column -->
                <ng-container matColumnDef="id_pedido">
                  <th mat-header-cell *matHeaderCellDef> ID Pedido </th>
                  <td mat-cell *matCellDef="let element"> {{element.IDPedido}} </td>
                </ng-container>

                <ng-container matColumnDef="fecha_alta">
                  <th mat-header-cell *matHeaderCellDef> Fecha de Captura </th>
                  <td mat-cell *matCellDef="let element"> {{element.FechaAlta}} </td>
                </ng-container>

                <ng-container matColumnDef="id_cliente">
                  <th mat-header-cell *matHeaderCellDef> ID del Cliente </th>
                  <td mat-cell *matCellDef="let element"> {{element.IDCliente}} </td>
                </ng-container>

                <ng-container matColumnDef="nombre_cliente">
                  <th mat-header-cell *matHeaderCellDef> Nombre del Cliente </th>
                  <td mat-cell *matCellDef="let element"> {{element.NombreCliente}} </td>
                </ng-container>

                <ng-container matColumnDef="empleado">
                  <th mat-header-cell *matHeaderCellDef> Vendedor </th>
                  <td mat-cell *matCellDef="let element"> {{element.Empleado}} </td>
                </ng-container>

                <ng-container matColumnDef="tu_precio">
                  <th mat-header-cell *matHeaderCellDef> Total con IVA </th>
                  <td mat-cell *matCellDef="let element"> {{element.TuPrecioTotal | currency}} </td>
                </ng-container>

                <ng-container matColumnDef="status">
                  <th mat-header-cell *matHeaderCellDef> Estatus de colocación en SAP </th>
                  <td mat-cell *matCellDef="let element"> {{element.Estatus}} </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef> Acciones </th>
                  <td mat-cell *matCellDef="let element" class="actions-td"> 
                    <button mat-icon-button color="primary" class="btn-table" (click) = "show_detail(element.IDPedido)"><mat-icon>visibility</mat-icon></button>
                    <button mat-icon-button color="primary" class="btn-table" (click) = "download_order(element.IDPedido)"><mat-icon>download</mat-icon></button>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

  <!-- Mensaje de no existencias -->
  <ng-container *ngIf="!orders || orders.length === 0">
    <tr>
      <td class="no-data-cell" [attr.colspan]="displayedColumns.length">
        No hay existencias disponibles.
      </td>
    </tr>
  </ng-container>
                
              </table>

             
              <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="10" [showFirstLastButtons]="true" (page)="onPageChange($event)"></mat-paginator>
               <!-- <table class="table table-hover mbn" id="orders-index">
                 <thead>
                   <tr>
                     <th>ID pedido</th>
                     <th>Fecha de captura</th>
                     <th>ID del cliente</th>
                     <th>Nombre del cliente</th>
                     <th>Vendedor</th>
                     <th>Total con IVA</th>
                     <th>Estatus de colocación en SAP</th>
                     <th>Acciones</th>
                   </tr>
                 </thead>
                 <tbody *ngIf="isDataLoaded == 1">
                  <tr>
                    <td colspan="7" align="center"><mat-spinner [diameter]="50"></mat-spinner></td>
                  </tr>
                </tbody>
                 <tbody *ngIf="isDataLoaded == 2">
                   <tr *ngFor="let column of orders; let i = index">
                     <td>{{ column.IDPedido }}</td>
                     <td>{{ column.FechaAlta }}</td>
                     <td>{{ column.IDCliente }}</td>
                     <td>{{ column.NombreCliente }}</td>
                     <td>{{ column.Empleado }}</td>
                     <td>{{ column.TuPrecioTotal | currency }}</td>
                     <td>{{ column.Estatus }}</td>
                     <td>
                    
                      <button type="button" #tooltip="matTooltip" matTooltip="Ver" (click) = "show_detail(i)"><i class="fa fa-eye"></i></button>
                      
                      <button *ngIf="copy_permissions == 1" type="button" #tooltip="matTooltip" matTooltip="Copiar pedido" (click) = "copy_order(column.IDPedido)"><i class="fa fa-copy"></i></button>
                      <button disabled="{{disabled}}" type="button" #tooltip="matTooltip" matTooltip="Descargar" (click) = "download_order(column.IDPedido)"><i class="fa fa-download"></i></button>
                      <label class="{{column.IDPedido}}" style="display:none">D2escargando...</label>
                     </td>
                     
                   </tr>        
                 </tbody>
               </table> -->
             </div>
           </div>
        </div>
    </div>
</div>