// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // url_api: 'http://dapesaqa.engranedigital.com/api',
  // url_api: 'http://localhost:8000/api',
  // url_api: 'http://http://localhost:8000/api',
  // url_api_aws: 'https://zkmn0e2csk.execute-api.us-east-1.amazonaws.com/sandbox',
  // url_api: 'http://democlon.dapesa.com/api',
  // url_api: 'http://backclon.dapesa.com/api',
  // url_api: 'https://back.dapesa.com/api',
  // url_api: 'http://34.201.5.78/api',
  url_api: 'https://demo.dapesa.com/api',
  // firebase: {
  //   apiKey: "AIzaSyAtnAZ6h2CTG7K4GksEoiNbr9949QFIvi0",
  //   authDomain: "rackup-87c8b.firebaseapp.com",
  //   projectId: "rackup-87c8b",
  //   storageBucket: "rackup-87c8b.appspot.com",
  //   messagingSenderId: "4696952544",
  //   appId: "1:4696952544:web:702c062f914edbbd844a85",
  //   measurementId: "G-EJ7F2SN5HM"
  // }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
