import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './footer/footer.component';
import { LayoutComponent } from './layout/layout.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { TreeComponent } from './tree/tree.component';


//Material
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatTreeModule} from '@angular/material/tree';
import {MatExpansionModule} from '@angular/material/expansion'; 
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCard, MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';


@NgModule({
  declarations: [
    LayoutComponent,
    FooterComponent,
    SidenavComponent,
    TreeComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatSidenavModule,
    MatListModule,
    MatToolbarModule,
    MatIconModule,
    MatExpansionModule,
    MatTreeModule,
    MatFormFieldModule,
    MatDialogModule,
    MatCardModule,
    MatTabsModule
  ],
  exports: [
    FooterComponent,
    SidenavComponent,
    TreeComponent,
    MatListModule,
    MatToolbarModule,
    MatIconModule,
    MatExpansionModule,
    MatTreeModule
  ],
  providers: []
})
export class SharedModule { }
