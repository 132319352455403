import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyCustom',
  pure: true
})
export class CurrencyCustomPipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): unknown {
    if (isNaN(value) || value === null) {
      return ''; // Manejar valores inválidos
    }
    
    // Aplicar truncamiento de 2 dígitos
    const roundedValue = Math.round(value * 100) / 100;

    // Convertir a formato de moneda con separador de miles y punto decimal
    const formattedValue = roundedValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return '$' + formattedValue;
  }

}
