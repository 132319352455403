import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root',
})
export class CustomersService {
  constructor(private http: HttpClient) {}

  public getAll(id_user: any, type_user: any): Observable<any> {
    const uri = `${environment.url_api}/clientes/${id_user}/${type_user}`;
    // console.log(uri);
    return this.http.get(
      `${environment.url_api}/clientes/${id_user}/${type_user}`
    );
  }

  public get(Id_cliente: any, id_user: any, type_user: any): Observable<any> {
    //console.log("params service customer"+id)
    return this.http.get(
      `${environment.url_api}/direcciones/cliente/${Id_cliente}/${id_user}/${type_user}`
    );
  }

  public observations(Id_cliente: any): Observable<any> {
    //console.log("params service customer"+id)
    return this.http.get(
      `${environment.url_api}/observaciones/cliente/${Id_cliente}`
    );
  }

  public priorities(): Observable<any> {
    return this.http.get(`${environment.url_api}/prioridades/entrega`);
  }

  public tranportistas(): Observable<any> {
    return this.http.get(`${environment.url_api}/transportistas`);
  }

  public getBloqueoCliente(Id_cliente: any): Observable<any> {
    // console.log(Id_cliente)
    return this.http.get(
      `${environment.url_api}/cliente/bloqueo/${Id_cliente}`
    );
  }

  public getBloqueosRutas(): Observable<any> {
    return this.http.get(`${environment.url_api}/bloqueo/rutas`);
  }

  public getValidacionCliente(objectValidate): Observable<any> {
    const { Id_usuario, Id_cliente, Id_sesion } = objectValidate;
    return this.http.get(
      `${environment.url_api}/cliente/validacion/${Id_cliente}/${Id_usuario}/${Id_sesion}`
    );
  }

  public postValidacionCliente(objectValidate): Observable<any> {
    const { Id_usuario, Id_cliente, Id_sesion } = objectValidate;
    const body = {};
    return this.http.post(
      `${environment.url_api}/cliente/validacion/${Id_cliente}/${Id_usuario}/${Id_sesion}`,
      body
    );
  }

  public updateValidacionCliente(objectValidate): Observable<any> {
    const { Id_usuario, Id_cliente, Id_sesion } = objectValidate;
    const body = {};
    return this.http.put(
      `${environment.url_api}/cliente/validacion/${Id_cliente}/${Id_usuario}/${Id_sesion}`,
      body
    );
  }
}
