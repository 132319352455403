import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { ContentModule } from './content/content.module';
import { SharedModule } from "./shared/shared.module";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DataTablesModule } from 'angular-datatables';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

//Material
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button'; 
import { MatFormFieldModule } from '@angular/material/form-field'; 

//Interceptores
import { AuthInterceptorService } from './shared/auth-interceptor.service';


//Components
import { AppComponent } from './app.component';
import { CustomersComponent } from './content/customers/customers.component';

import {MatSelectModule} from '@angular/material/select';
import {MatPaginatorModule} from '@angular/material/paginator';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ImageCarouselComponent } from './image-carousel/image-carousel.component';


// import { ModalModule } from 'ngx-bootstrap/modal'
@NgModule({
  declarations: [
    AppComponent,
    
    
  ],
  imports: [
    BrowserModule,
    SharedModule,
    BrowserAnimationsModule,
    FormsModule,
    MatButtonModule,
    MatFormFieldModule,
    DataTablesModule,
    ContentModule,
    AppRoutingModule,
    HttpClientModule,
    MatSelectModule,
    MatPaginatorModule,
    MatAutocompleteModule,
   

    // ModalModule
  ],
  exports: [
    FormsModule,
    MatButtonModule,
    MatFormFieldModule
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptorService,
    multi: true}],
  bootstrap: [AppComponent]
})
export class AppModule { }
