import { Component,Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-image-carousel',
  templateUrl: './image-carousel.component.html',
  styleUrls: ['./image-carousel.component.css'],
})
export class ImageCarouselComponent {
  public images:any
  public loading: boolean = true;

  constructor(
    // public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}
  currentIndex = 0;

  ngOnInit(){
    
    const bannerUrls = this.data.images.split(';');
  
    this.images = bannerUrls
    this.loading = false
   
  }
  
  changeSlide(index: number) {
    this.currentIndex = index;
  }

  prevSlide() {
    this.currentIndex = (this.currentIndex - 1 + this.images.length) % this.images.length;
  }

  nextSlide() {
    this.currentIndex = (this.currentIndex + 1) % this.images.length;
  }
}