import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public LoginForm: FormGroup;
  public show: boolean = true;
  public current_user: any;

  constructor(
    private fb: FormBuilder,
    private loginService: LoginService,
    private router: Router
  ) 
  {
    this.LoginForm = fb.group({
      user: ['', [Validators.required, Validators.minLength(8)]],
      password: ['', Validators.required]
    });

    
   }

  ngOnInit(): void {
  }

  togglePasswordVisibility(){
    this.show = !this.show; 
  }
  errorAlert(title: string,message: string){
    Swal.fire({
      icon: 'error',
      title: title,
      text: message,
      confirmButtonText: 'Aceptar',
    })    
  }

  login(){
    if (this.LoginForm.invalid) {
      return;
    }

    var user = {
      "usuario": this.LoginForm.value['user'],
      "password": this.LoginForm.value['password'],
    }
    this.loginService.login(this.LoginForm.value['user'], this.LoginForm.value['password']).subscribe(
      data => {this.current_user = data;
      //console.log("Login"+JSON.stringify(this.current_user))
      LoginService.set_user(this.current_user);
      const jwt = this.current_user["access_token"]
      localStorage.setItem('jwt', jwt);
      localStorage.setItem('user', JSON.stringify(this.current_user));
      this.router.navigate(['./customers']);
    },
      error => { 
        this.errorAlert("Autenticación","Usuario o contraseña incorrectos");
        //alert("Usuario o contraseña incorrectos "+JSON.stringify(error)) 
      });
  }

  showPassword() {
    this.show = !this.show;
  }

}
