import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { LoginService } from '../services/login.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {
  public token: any;

  constructor(
    private router: Router
  ) 
  { 
    
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let request = req;
    
    // if (request.body) {
      this.token = localStorage.getItem('jwt');
    // }
    
    if (this.token) {
      const headers = new HttpHeaders({
        'Authorization': `Bearer ${ this.token }`,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      });
      
      request = req.clone({ headers });
    }

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
      
        if (err.status === 401) {
          this.router.navigateByUrl('/');
        }

        return throwError( err.statusText );

      })
    );
    
  }
}
