
<div class="container-fluid">
  <div class="col-sm-12">
      <div class="card">
         <div class="card-header">

           <div class="col-sm-6" >             
              <a class="btn btn-primary" [routerLink]="'/products/new'" >
                <i class="icon-plus"></i> Catálogo
              </a>
          </div>

         </div>

         

         <div class="card-body">
           <div class="table-responsive">
             <table class="display" id="products-all">
               <thead>
                 <tr>
                   <th>IDMaterial</th>
                   <th>Terminologia</th>
                   <th>Descripción del Producto</th>
                   <th>Categoría</th>
                 </tr>
               </thead>
               <tbody>
                 <tr *ngFor="let column of products" id="tr_product_{{column.IDMaterial}}">
                  <td>{{column.IDMaterial}}</td>
                  <td>{{column.Terminologia}}</td>
                  <td>{{column.Descripcion}}</td>
                  <td>{{column.Categoria}}</td>
                 </tr>        
               </tbody>
             </table>
           </div>
         </div>
       </div>
     </div>
 </div>