<mat-tab-group (selectedTabChange)="TabChange($event)" animationDuration="0ms" #allTabs>
  <mat-tab label="Nuevo pedido">
    <br>
    <!-- <div class="row">
      <div class="col-md-12">
        <div class="col-md-6 flex">
          <label class="general-label">Importar CSV:</label>
          <input type="file" accept=".csv" (change)="upload_csv($event)">
        </div>
        <div class="col-md-6 flex">
          <label class="general-label">Importar CSV:</label>
            <label class="field prepend-icon append-button file">
              <span class="btn btn-primary"><i class="fa fa-upload"></i>Seleccione CSV</span>
              <input type="file" class="gui-file" name="file1" id="file1">
            </label>
          
        </div>
      </div>
    </div> 
    <br>-->



    <div class="row">
      <div class="col-md-12 col-sm-12">
        <div class="col-md-6  col-sm-12">
          <ng-select2 id="select2-customer" class="js-example-placeholder-multiple" [data]="customers"
            [placeholder]="'Seleccione una opción...'" [allowClear]="true"
            (valueChanged)="get_customer($event);setFocusFirstFilter()" width="100%" height="10px;"
            style="width: 100%;"></ng-select2>
        </div>
        <div class="col-md-2  col-sm-12 flex">
          <label class="general-label-14">Estatus:</label>
          <input *ngIf="customer" id="status_cust" value=" {{ customer.Estatus }}" type="text" class="gui-input input-status"
            placeholder="Disabled no icon" disabled [ngClass]="{'input-inactive': customer.Estatus === 'Inactivo'}">
          
            
        <div class="col-md-2 col-sm-12 flex" >
            <label class="general-label-14">Ruta:</label>
            <input *ngIf="customer"  class="input-route" [ngClass]="{'special-class': isRouteBlock}" value=" {{ customer.Ruta }}" type="text"
            placeholder="Disabled no icon" disabled>
          </div>
        </div>

        <div class="col-md-3  col-sm-12 flex">
          <mat-spinner *ngIf="isCustLoaded == 1" [diameter]="50"></mat-spinner>
          <mat-spinner *ngIf="AllCustLoaded == 1" [diameter]="50"></mat-spinner>
        </div>
      </div>
    </div>
    <br>



    <p class="general-title">Paso 1: Complete la información del cliente:</p>

    <mat-accordion>
      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <ol class="breadcrumb">
              <li class="breadcrumb-item active">Información del cliente</li>
            </ol>

          </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="row">
          <div class="col-md-12  col-sm-12">
            <div class="col-md-6  col-sm-12">
              <label class="general-label-14">Condiciones de pago</label><br>
              <input *ngIf="customer" value="{{ customer.CondicionesPago }} " type="text" class="gui-input" readonly>
            </div>
            <div class="col-md-6 col-sm-12">

            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12  col-sm-12">
            <div class="col-md-6  col-sm-12">
              <label class="general-label-14">Dirección de facturación</label><br>

              <ng-select2 [(value)]="address_default.consecutivo" 
                class="js-example-placeholder-multiple" [data]="address_fact" [placeholder]="'Seleccione una opción...'"
                [allowClear]="true" (valueChanged)="get_color_status(customer.IDEstatus)" width="100%" height="10px;"
                style="width: 100%;" [disabled]="true"></ng-select2>
              <br>
              <div *ngFor="let column of address_fact_detail">
                <label class="general-label-14">Dirección: {{column.calle}}, {{column.colonia}} CP:
                  {{column.cp}}</label><br>
                <label class="general-label-14">Teléfono : {{column.telefono1 }}</label><br>
                <label class="general-label-14"> {{column.estado }}, {{column.pais }}, {{ column.ciudad }}</label><br>
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <label class="general-label-14">Dirección de entrega</label><br>
              <ng-select2 [(value)]="address_default.consecutivo" 
                class="js-example-placeholder-multiple" [data]="address_cust" [placeholder]="'Seleccione una opción...'"
                [allowClear]="true" (valueChanged)="update_address($event)" width="100%" height="10px;"
                style="width: 100%;"></ng-select2>
              <br>
              <div *ngFor="let column of address_entr_detail">
                <label *ngIf="column" class="general-label-14">Dirección: {{column.calle}}, {{column.colonia}} CP:
                  {{column.cp}}</label><br>
                <label *ngIf="column" class="general-label-14">Teléfono : {{column.telefono1 }}</label><br>
                <label *ngIf="column" class="general-label-14"> {{column.estado }}, {{column.pais }}, {{ column.ciudad
                  }}</label><br>
              </div>
            </div>
          </div>
        </div>


        <div class="row">
          <div class="col-md-12 col-sm-12">
            <div class="col-md-6  col-sm-12">
              <label class="general-label-14">Facturar contado MOS</label>
              <input (change)="save_extra_data()" type="checkbox" name="terms" id="bill" class="bill">
            </div>
            <div class="col-md-6">

            </div>
          </div>
        </div>
        <hr class="short alt">

        <div class="row">
          <div class="col-md-12 col-sm-12">
            <div class="col-md-6 col-sm-12">
              <label class="general-label-14">Seguro %</label>
              <input *ngIf="customer" value=" {{ customer.PorcentajeSeguro }}" type="text" class="gui-input"
                placeholder="Disabled no icon" readonly>
            </div>
            <div class="col-md-6 col-sm-12">
              <label class="general-label-14">Costo de envio</label>
              <input *ngIf="customer" value=" {{ customer.CostoEnvio }}" type="text" class="gui-input"
                placeholder="Disabled no icon" readonly>

            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 col-sm-12">
            <div class="col-md-6 col-sm-12">
              <label class="general-label-14">Transporte</label><br>

              <ng-select2 *ngIf="customer" [(value)]="customer.IDTransportista" class="js-example-placeholder-multiple"
                 [data]="tranportistas" [placeholder]="'Seleccione una opción...'"
                [allowClear]="true" (valueChanged)="update_transporte($event)" width="100%" height="10px;"
                style="width: 100%;"></ng-select2>
            </div>
            <div class="col-md-6 col-sm-12">
              <label class="general-label-14">Prioridad de entrega</label><br>
              <ng-select2 *ngIf="customer" [(value)]="customer.IDPrioridadEntrega"
                class="js-example-placeholder-multiple"  [data]="delivery_priorities"
                [placeholder]="'Seleccione una opción...'" [allowClear]="true" width="100%" height="10px;"
                style="width: 100%;" [disabled]="true"></ng-select2>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 col-sm-12">
            <div class="col-md-12 col-sm-12">
              <label class="general-label-14">Observaciones del cliente</label><br>
              <textarea value="{{ observations }}" class="gui-textarea" id="comment" name="comment"
                placeholder="Observaciones del cliente..." disabled></textarea>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <br><br>

    <div *ngIf="customer && customer.IDJerarquiaCliente != '' && customer.IDJerarquiaCliente != null">
      <p class="general-title">Paso 2: Agregar Artículos al pedido:</p>

      <div class="row">
        <div class="col-md-12 col-sm-12">
          <div [hidden]="customer == undefined" class="col-md-2 col-sm-12">
            <!-- <mat-label>Campo de busqueda</mat-label> -->
            <input #searchField (change)="first_filter($event)" id="first-filter" type="text" class="gui-input"
              placeholder="Campo de búsqueda" (focus)="this.activeSearchAdv = false">
          </div>
          <div class="col-md-6 col-sm-12">
            <mat-spinner class="spinner_searching" [diameter]="50" *ngIf="isDataLoaded == 1"></mat-spinner>
          </div>
          <div *ngIf="isDataLoaded == 2" class="col-md-6 col-sm-12">
            <mat-form-field appearance="standard">
              <mat-label>Búsqueda avanzada</mat-label>
              <input id="input-search-adv" class="second_filter" matInput (keyup)="applyFilter($event)"
                placeholder="Search" #input (focus)="this.activeSearchAdv = true">
            </mat-form-field>
            <!-- <mat-spinner class="spinner_searching" [diameter]="50" *ngIf="isDataLoaded == 1"></mat-spinner> -->
            <div *ngIf="isDataLoaded == 2" class="mat-elevation-z8 table-loaded">
              <table #tableProducts mat-table [dataSource]="dataSource" matSort id="products-filter">

                <!-- Clave Column -->
                <ng-container matColumnDef="Clave">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Producto </th>
                  <td mat-cell *matCellDef="let column; let i = index">
                    <a (click)="get_product(column.clave, 1, -1, true)"> {{ column.clave }}</a>
                    <input [id]="column.indexTemp" class="input-fake-focus" type="text" style="position: absolute; z-index: -1;">
                  </td>
                </ng-container>

                <!-- Descripción Column -->
                <ng-container matColumnDef="Descripción">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Descripción del producto 
                  </th>
                  <td mat-cell *matCellDef="let row"> {{ row.descripcion }}% 
                    
                      <div class="recuadro-button">
                        <button  *ngIf="row.promocion" class="cuadro-promo" (click)="openSliderPromotions(row.banner)">
                          <div >
                            PROMO
                          </div>
                        </button>
                      </div>
                    </td>
                </ng-container>


                <!-- Cantidad minima Column -->
                <!-- <ng-container matColumnDef="Cantidad mínima de venta">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad mínima de venta</th>
                  <td mat-cell *matCellDef="let row"> {{ row.multiplo_venta | number : '1.0-0' }} </td>
                </ng-container> -->

                <!-- Unidad Medida -->
                <ng-container matColumnDef="Unidad de medida">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Unidad de medida </th>
                  <td mat-cell *matCellDef="let row"> {{row.UnidadMedida | uppercase  }} </td>
                </ng-container>

                <!-- Marca Column -->
                <ng-container matColumnDef="Marca">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Marca </th>
                  <td mat-cell *matCellDef="let row"> {{ row.marca }} </td>
                </ng-container>

                <!-- Terminología -->
                <ng-container matColumnDef="Terminología">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Terminología </th>
                  <td mat-cell *matCellDef="let row"> {{row.descripcion_2 }} </td>
                </ng-container>



                <!-- Existencia -->
                <ng-container matColumnDef="Clasificación">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Clasificación</th>
                  <td mat-cell *matCellDef="let row"> {{row.clasificacion }} </td>
                </ng-container>


                <!-- Ver -->
                <ng-container matColumnDef="Detalle">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Detalle</th>
                  <td mat-cell *matCellDef="let row" class="td-actions-search">
                    <button class="" type="button" #tooltip="matTooltip"
                      matTooltip="Ver" (click)="show_detail2(row.clave)">
                      <i class="fa fa-search">

                      </i>
                    </button>
                      
                  </td>
                </ng-container>

                <!-- Cerrar -->
                <ng-container matColumnDef="Cerrar">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> <button class="closed_table" type="button"
                      (click)="clean_searcher()"><i class="fa fa-close"></i></button></th>
                  <td mat-cell *matCellDef="let row"></td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                  [ngClass]="{'row-selected': articleSelected == row.indexTemp}"></tr>

                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
                </tr>
              </table>

              <!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons></mat-paginator> -->
              <mat-paginator [length]="length" [pageSize]="pageSize" [pageIndex]="pageNo"
                [pageSizeOptions]="[5, 10, 25, 100]" (page)="pageEvents($event)"></mat-paginator>
            </div>
          </div>

          <div class="col-md-4 col-sm-12 content-btns-cvs-clear">
            <div class="content-btns-cvs">
              <a id="btn-csv-download" class="csv_download" href="/assets/files/plantilla_csv.csv" matTooltip="CSV plantilla">
                CSV plantilla
              </a>
             
              <button id="btn-csv-upload" class="field prepend-icon append-button file btn btn-primary" matTooltip="Seleccione CSV" (click)="openUploadCsv()">
                <i class="fa fa-upload"></i> Seleccione CSV                
              </button>

              <button id="btn-csv-upload" class="field prepend-icon append-button file btn btn-primary  button-download" matTooltip="Descargar orden" (click)="downloadCSV()">
                <i class="fa fa-download"></i> Descargar Orden             
              </button>
              <input #uploadFileCsv class="file_csv" type="file" accept=".csv" hidden (change)="upload_csv($event)">
            </div>
            <button class="btn btn-danger" type="button" #tooltip="matTooltip" matTooltip="Borrar"
              (click)="reset_rows()"><i class="fa fa-trash"></i>Borrar Todo</button>
          </div>

        </div>
      </div><br>


      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-body">


                <form class="row needs-validation" [formGroup]="NewOrderForm">
                  <div class="col-sm-12">
                    <div class="table-responsive">
                      <table id="products-grid" class="table table-hover mbn" border="3">
                        <thead>
                          <tr class="active">
                            <th>Acciones</th>
                            <th>Num</th>
                            <th>Producto</th>
                            <!-- <th>ATP</th> -->
                            <th>Terminología</th>
                            <th>Descripción del producto</th>
                            <th>Línea</th>
                            <th>Cantidad Solicitada</th>
                            <th>Cantidad Disponible</th>
                            <th>UM</th>
                            <th>Total Pzas</th>
                            <th>Precio unitario</th>
                            <th>% Descuento estandar</th>
                            <th>Precio unitario - Descuento estandar</th>
                            <th>% Descuento comercial</th>
                            <th>Precio unitario - Descuento comercial</th>
                            <th>Precio neto pronto pago</th>
                            <th>Valor neto pronto pago</th>
                            <th>Valor neto</th>
                            
                          </tr>
                        </thead>

                        <tbody *ngIf="CsvLoaded == 1">
                          <tr>
                            <td colspan="15" align="center">
                              <mat-spinner [diameter]="50"></mat-spinner>
                            </td>
                          </tr>
                        </tbody>


                        <tbody *ngIf="CsvLoaded == 2">
                          <!-- class='{{ item.status_refresh }}' -->
                          <tr *ngFor="let item of rows; let i = index" 
                            id="product_clave_{{ item.claveConversion }}">
                            <td class="{{ item.status_amount }}">
                              <button type="button" #tooltip="matTooltip" matTooltip="Eliminar"
                                (click)="delete_row(i, item.clave)"><i class="fa fa-trash"></i></button>
                              <button type="button" #tooltip="matTooltip" matTooltip="Ver"
                                (click)="get_product(item.clave, 1, i)"><i class="fa fa-eye"></i></button>
                            </td>
                            <td class="{{ item.status_amount }}">{{ i + 1 }}</td>
                            <td class="{{ item.status_amount }}">{{ item.clave }}</td>
                            <!-- <td class="{{ item.status_amount }}">
                              <div *ngIf="item.status_amount == 'warning'">
                                <button type="button" #tooltip="matTooltip"
                                  matTooltip="Disponibles: {{ item.existencia | number : '1.0-0' }}"><i
                                    class="fa fa-circle"></i></button>
                              </div>
                              <div *ngIf="item.status_amount != 'warning'">
                                <button type="button"><i class="fa fa-circle"></i></button>
                              </div>
                            </td> -->
                            <td class="{{ item.status_amount }}">{{ item.familia }}</td>
                            <td class="{{ item.status_amount }}">{{ item.descripcion }}  
                              <div class="recuadro-button">
                                <button  *ngIf="item.promocion" class="cuadro-promo" (click)="openSliderPromotions(item.banner)">
                                  <div >
                                    PROMO
                                  </div>
                                </button>
                              </div>
                            </td>
                            <td class="{{ item.status_amount }}" align="right">{{ item.CategoriaProducto }}</td>
                            <td class="amount  {{ item.status_amount }}">
                              <input class="table-cell " *ngIf="!item.copy" [id]="'product_amount_'+item.clave"
                                (change)="get_product(item.clave, $event, i, false, true)" type="number"
                                disabled="{{disabled}}" value="{{ item.request_amount }}"
                                min="{{ item.multiplo_venta }}" (keydown.esc)="pressEscProductOrder()"
                                (keydown.enter)="pressEnterProductOrder('product_amount_'+item.clave)"
                                (blur)="this.activeSearchAdv = false">

                              <input class="table-cell " *ngIf="item.copy" [id]="'product_amount_'+item.clave"
                                (change)="get_product(item.clave, $event, i, false, true)" type="number"
                                disabled="{{disabled}}" value="{{ item.Cantidad }}" min="{{ item.multiplo_venta }}"
                                (keydown.esc)="pressEscProductOrder()"
                                (keydown.enter)="pressEnterProductOrder('product_amount_'+item.clave)"
                                (blur)="this.activeSearchAdv = false">
                            <td  class="{{ item.status_amount }}">{{item.existencia < item.total_pzas ?( item.existencia | number:'1.0-0' ): ( item.total_pzas  | number:'1.0-0' )}}</td>
                            <td class="{{ item.status_amount }} um">
                              <!-- <mat-select *ngIf="item.measurement_units" [(value)]="item.unidad_medida_clave"
                                (selectionChange)="change_um(item.unidad_medida_clave, i)" [disabled]="true">
                                <mat-option value="{{ unidad.IDUnidadMedida }}"
                                  *ngFor="let unidad of item.measurement_units">{{ unidad.UnidadMedida }}</mat-option>
                              </mat-select>
                              
                              <mat-select *ngIf="!item.measurement_units" [(value)]="item.unidad_medida_clave"
                                (selectionChange)="change_um(item.unidad_medida_clave, i)" [disabled]="true">
                                <mat-option value="{{ item.unidad_medida_clave }}">{{ item.UnidadMedida }}</mat-option>
                              </mat-select> -->
                              {{item.UnidadMedida | uppercase}}
                            </td>
                            <td class="total_pzas {{ item.status_amount }}">{{ item.total_pzas | number }}</td>
                            <td class="{{ item.status_amount }}" align="right">{{ item.precio | currency }}</td>
                            <td class="{{ item.status_amount }}" align="right">{{ item.standard_discount |  number: '1.2-2' }}%</td>
                            <td class="standard_price {{ item.status_amount }}" align="right">{{ item.price_disc_standar  |  currency  }}
                            </td>
                            <td class="{{ item.status_amount }}" align="right">{{ item.comercial_discount | number: '1.2-2' }}%</td>
                            <td class="{{ item.status_amount }}" align="right">{{ item.price_disc_comer | currency }}</td>
                            <td class="{{ item.status_amount }}" align="right">{{ item.price_disc_comer_pp | currency }}</td>
                            <td class="{{item.status_amount}}" align="right">{{ (item.price_disc_comer_pp * ( item.existencia < item.total_pzas ? item.existencia : item.total_pzas ) ) | currency }}</td>
                            <td class="total_row {{ item.status_amount }}" align="right">{{ item.total_row | currency  }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <br><br>
                    <mat-card>
                      <div class="row">
                        <div class="col-md-12 col-sm-12">
                          <div class="col-md-6 col-sm-12">
                            <label class="general-label-14">Observaciones del pedido</label>
                            <textarea [(ngModel)]="texto" maxlength="250" rows="30" cols="30" style="resize: none; height: 70px;" (input)="contarCaracteres()" (change)="save_extra_data()" value="{{ obs_order }}" formControlName="obs_order"
                              class="gui-textarea" id="obs_order" name="comment"
                              placeholder="Observaciones..."></textarea>
                              <span>{{ contador }} / {{ limiteCaracteres }}</span>
                            <br><br>
                            <label class="general-label-14">Orden de compra</label><br>
                            <input [(ngModel)]="textoOrdenCompra" (input)="contarCaracteres()"  (change)="save_extra_data()"  maxlength="33" value="{{ num_order }}" formControlName="num_order"
                              id="num_order" type="text" class="gui-input" placeholder="Número de orden">
                              <!-- <span>{{ contadorOrdenCompra }} / {{ limiteCaracteresOrdenCompra }}</span> -->
                            <div
                              *ngIf="NewOrderForm.controls.num_order.touched && NewOrderForm.controls.num_order.invalid">
                              <mat-error *ngIf="NewOrderForm.controls.num_order.errors?.required">Requerido</mat-error>
                              <mat-error *ngIf="NewOrderForm.controls.num_order.errors?.minlength">Minimo 8 caracteres
                              </mat-error>
                            </div>
                          </div>
                          <div *ngIf="customer" class="col-md-6">
                            <table class="table table-hover mbn" id="total-table">
                              <thead>
                                <tr>
                                  <th colspan="2">Pago Neto</th>
                                  <th colspan="2">Pago con PP (Pronto pago) {{ customer.PP }}%</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Seguro:</td>
                                  <td><label *ngIf="customer.suma">{{ customer.seguro | currency }}</label></td>
                                  <td>Seguro:</td>
                                  <td><label *ngIf="customer.suma_pp">{{ customer.seguro_pp | currency
                                      }}</label></td>
                                </tr>
                                <tr>
                                  <td>Costo envio:</td>
                                  <td>{{ customer.CostoEnvio | currency }}</td>
                                  <td>Costo envio:</td>
                                  <td>{{ customer.envio_pp | currency }}</td>
                                </tr>
                                <tr>
                                  <td>Suma:</td>
                                  <td>{{ customer.suma | currency }}</td>
                                  <td>Suma:</td>
                                  <td>{{ customer.suma_pp | currency }}</td>
                                </tr>
                                <tr>
                                  <td>Subtotal:</td>
                                  <td>{{ customer.subtotal |currency }}</td>
                                  <td>Subtotal:</td>
                                  <td>{{ customer.subtotal_pp |currency }}</td>
                                </tr>
                                <tr>
                                  <td>IVA:</td>
                                  <td>{{ customer.total_iva |currency }}</td>
                                  <td>IVA:</td>
                                  <td>{{ customer.total_iva_pp |currency }}</td>
                                </tr>
                                <tr>
                                  <td>Total:</td>
                                  <td>{{ customer.total |currency }}</td>
                                  <td>Total:</td>
                                  <td>{{ customer.total_pp |currency }}</td>
                                </tr>
                              </tbody>
                            </table>

                          </div>
                        </div>
                      </div>
                    </mat-card>
                    <!-- <p><b>Pedido Completo puede continuar</b></p> -->
                    <p class="precio-minimo-label"><b>Te falta <span class="price">{{totalFaltantePrecioMin | currency }}</span> para el mínimo de compra requerido ( {{precioMinimoXCliente | currency}} pesos en productos con existencia)
                    </b></p>

                    <p *ngIf = "mensajeErrorSeleccionTransportista"><b>Falta Seleccionar Transportista para el Cliente</b></p>
                    <br>
                    <div>
                      
                      <button type="button" class="pull-right btn btn-primary" mat-raised-button tabindex="-1"
                        (click)='moveToSelectedTab("Resumen de pedido del cliente")' [disabled]="!(rows.length > 0 && isMinimunSale === true && mensajeErrorSeleccionTransportista === false)" ><i
                          class="fa fa-arrow-circle-right"></i>Continuar</button>
                    </div>
                    <!-- <div *ngIf="rows.length > 0">
                      <button type="button" class="pull-right btn btn-primary" mat-raised-button tabindex="-1"
                        (click)='moveToSelectedTab("Resumen de pedido del cliente")'><i
                          class="fa fa-arrow-circle-right"></i>Continuar</button>
                    </div>
                    <div *ngIf="rows.length == 0">
                      <button type="button" class="pull-right btn btn-primary" mat-raised-button tabindex="-1"
                        (click)='moveToSelectedTab("Resumen de pedido del cliente")' disabled="disabled"><i
                          class="fa fa-arrow-circle-right"></i>Continuar</button>
                    </div> -->
                  </div>
                </form>


              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="customer && customer.IDJerarquiaCliente == null">
      <p class="general-title">El cliente no tiene los datos necesarios para agregar artículos</p>
    </div>
    <div *ngIf="customer && customer.IDJerarquiaCliente == ''">
      <p class="general-title">El cliente no tiene los datos necesarios para agregar artículos</p>
    </div>
  </mat-tab>


  <!-- RESUMEN DEL PEDIDO DEL CLIENTE -->
  <mat-tab label="Resumen de pedido del cliente" #tab [disabled]='!(isMinimunSale === true && mensajeErrorSeleccionTransportista === false)'>

    <mat-accordion>
      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <ol *ngIf="customer" class="breadcrumb">
              <li class="breadcrumb-item active">Información del cliente: {{ customer.Nombre }} </li>
            </ol>

          </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="row">
          <div class="col-md-12">
            <div class="col-md-6">
              <label class="general-label-14">Condiciones de pago</label><br>
              <input *ngIf="customer" value="{{ customer.CondicionesPago }} " type="text" class="gui-input" readonly>
            </div>
            <div class="col-md-6">

            </div>
          </div>
        </div>



        <div class="row">
          <div class="col-md-12  col-sm-12">
            <div class="col-md-6  col-sm-12">
              <label class="general-label-14">Dirección de facturación</label><br>
              <ng-select2 [(value)]="address_default.consecutivo" id="select2-address"
                class="js-example-placeholder-multiple" [data]="address_fact" [placeholder]="'Seleccione una opción...'"
                [allowClear]="true" (valueChanged)="get_color_status(customer.IDEstatus)" width="100%" height="10px;"
                style="width: 100%;" [disabled]="true"></ng-select2>
              <br>
              <div *ngFor="let column of address_fact_detail">
                <label class="general-label-14">Dirección: {{column.calle}}, {{column.colonia}} CP:
                  {{column.cp}}</label><br>
                <label class="general-label-14">Teléfono : {{column.telefono1 }}</label><br>
                <label class="general-label-14"> {{column.estado }}, {{column.pais }}, {{ column.ciudad }}</label><br>
              </div>
            </div>
            <div class="col-md-6 col-sm-12">
              <label class="general-label-14">Dirección de entrega</label><br>
              <ng-select2 [(value)]="address_default.consecutivo" id="select2-address"
                class="js-example-placeholder-multiple" [data]="address_cust" [placeholder]="'Seleccione una opción...'"
                [allowClear]="true" (valueChanged)="update_address($event)" width="100%" height="10px;"
                style="width: 100%;" [disabled]="true"></ng-select2>
              <br>
              <div *ngFor="let column of address_entr_detail">
                <label *ngIf="column" class="general-label-14">Dirección: {{column.calle}}, {{column.colonia}} CP:
                  {{column.cp}}</label><br>
                <label *ngIf="column" class="general-label-14">Teléfono : {{column.telefono1 }}</label><br>
                <label *ngIf="column" class="general-label-14"> {{column.estado }}, {{column.pais }}, {{ column.ciudad
                  }}</label><br>
              </div>
            </div>
          </div>
        </div>




        <div class="row">
          <div class="col-md-12">
            <div class="col-md-6">
              <label class="general-label-14">Facturar contado MOS</label>
              <input *ngIf="bill == 'S'" type="checkbox" name="terms" id="bill" checked="checked" disabled>
              <input *ngIf="bill == 'N'" type="checkbox" name="terms" id="bill" disabled>
            </div>
            <div class="col-md-6">

            </div>
          </div>
        </div>
        <hr class="short alt">

        <div class="row">
          <div class="col-md-12">
            <div class="col-md-6">
              <label class="general-label-14">Seguro %</label>
              <input *ngIf="customer" value=" {{ customer.PorcentajeSeguro }}" type="text" class="gui-input"
                placeholder="Disabled no icon" readonly>
            </div>
            <div class="col-md-6">
              <label class="general-label-14">Costo de envio</label>
              <input *ngIf="customer" value=" {{ customer.CostoEnvio }}" type="text" class="gui-input"
                placeholder="Disabled no icon" readonly>

            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="col-md-6">
              <label class="general-label-14">Transporte</label><br>
              <ng-select2 *ngIf="customer" [(value)]="customer.IDTransportista" class="js-example-placeholder-multiple"
                id="select2-transporte" [data]="tranportistas" [placeholder]="'Seleccione una opción...'"
                [allowClear]="true" (valueChanged)="update_transporte($event)" width="100%" height="10px;"
                style="width: 100%;" [disabled]="true"></ng-select2>
            </div>
            <div class="col-md-6">
              <label class="general-label-14">Prioridad de entrega</label><br>
              <ng-select2 *ngIf="customer" [(value)]="customer.IDPrioridadEntrega"
                class="js-example-placeholder-multiple" id="select2-priority" [data]="delivery_priorities"
                [placeholder]="'Seleccione una opción...'" [allowClear]="true" width="100%" height="10px;"
                style="width: 100%;" [disabled]="true"></ng-select2>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="col-md-6">
              <label class="general-label-14">Observaciones</label><br>
              <textarea  value="{{ observations }}" class="gui-textarea" id="comment" name="comment"
                placeholder="Observaciones..." disabled></textarea>
               
            </div>
            <div class="col-md-6">

            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <br><br>
    <p>Tiempo restante: {{ formatTime(remainingTime) }}</p>
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-body">


              <div class="col-sm-12">
                <div class="table-responsive">
                  <table id="products-grid" class="table table-hover mbn" border="3">
                    <thead>
                      <tr class="active">
                        <th>Num</th>
                        <th>Producto</th>
                        <!-- <th>ATP</th> -->
                        <th>Terminología</th>
                        <th>Descripción del producto</th>
                        <th>Línea</th>
                        <th>Cantidad Solicitada</th>
                        <th>Cantidad Disponible</th>
                        <th>UM</th>
                        <th>Total Pzas</th>
                        <th>Precio unitario</th>
                        <th>% Descuento estandar</th>
                        <th>Precio unitario - Descuento estandar</th>
                        <th>% Descuento comercial</th>
                        <th>Precio unitario - Descuento comercial</th>
                        <th>Precio neto pronto pago</th>
                        <th>Valor neto pronto pago</th>
                        <th>Valor neto</th>
                      </tr>
                    </thead>

                    <tbody *ngIf="isVerified == 1">
                      <tr>
                        <td colspan="15" align="center">
                          <mat-spinner [diameter]="50"></mat-spinner>
                        </td>
                      </tr>
                    </tbody>

                    <tbody *ngIf="isVerified == 2">
                      <!-- class='primary' -->
                      <tr *ngFor="let item of rows; let i = index" 
                        id="product_clave_{{ item.claveConversion }}">
                        <td class="{{ item.status_amount }}">{{ i + 1 }}</td>
                        <td class="{{ item.status_amount }}">{{ item.clave }}</td>
                        <!-- <td class="{{ item.status_amount }}">
                          <button type="button"><i class="fa fa-circle"></i></button>
                        </td> -->
                        <td class="{{ item.status_amount }}">{{ item.familia }}</td>
                        <td class="{{ item.status_amount }}">{{ item.descripcion }} 
                          <div class="recuadro-button">
                            <button  *ngIf="item.promocion_ganada" class="cuadro-promo" (click)="openSliderPromotions(item.banner)">
                              <div >
                                PROMO
                              </div>
                            </button>
                          </div>
                          
                        </td>
                        <td class="{{ item.status_amount }}" align="right">{{ item.CategoriaProducto }}</td>
                        <td  class="amonut {{ item.status_amount }}">
                          <input [id]="'product_amount_'+item.clave" type="number" value="{{ item.request_amount }}"
                            min="{{ item.multiplo_venta }}" disabled>
                        <td  class="{{ item.status_amount }}">{{item.existencia < item.total_pzas ?( item.existencia | number:'1.0-0' ): ( item.total_pzas  | number:'1.0-0' )}}</td>
                          <!-- <input *ngIf="item.copy" [id]="'product_amount_'+item.clave" type="number" value="{{ item.request_amount | number : '1.0-0' || '1'}}" min="{{ item.multiplo_venta }}" disabled> -->
                        <td class="{{ item.status_amount }}">
                          {{item.UnidadMedida | uppercase}}
                          <!-- <mat-select  disabled> 
                            <mat-option  value="opción1"> </mat-option>
                          </mat-select> -->

                          <!-- <mat-select *ngIf="!item.measurement_units" [(value)]="item.unidad_medida_clave"
                            (selectionChange)="change_um(item.unidad_medida_clave, i)" [disabled]="true">
                            <mat-option value="{{ item.unidad_medida_clave }}">{{ item.UnidadMedida }}</mat-option>
                          </mat-select> -->
                        </td>
                        <td class="total_pzas {{ item.status_amount }}">{{ item.total_pzas | number : '1.0-0'}}</td>
                        <td  class="{{ item.status_amount }}" align="right">{{ item.precio | currency }}</td>
                        <td class="{{ item.status_amount }}"  align="right">{{ item.standard_discount | number: '1.2-2' }}%</td>
                        <td class="standard_price {{ item.status_amount }}" align="right">{{ item.price_disc_standar | currency }}</td>
                        <td class="{{ item.status_amount }}" align="right">{{ item.comercial_discount | number: '1.2-2' }}%</td>
                        <td class="{{ item.status_amount }}" align="right">{{ item.price_disc_comer |currency }}</td>
                        <td class="{{ item.status_amount }}" align="right">{{ item.price_disc_comer_pp |currency }}</td>
                        <td class="{{item.status_amount}}" align="right">{{ (item.price_disc_comer_pp * ( item.existencia < item.total_pzas ? item.existencia : item.total_pzas ) ) | currency }}</td>
                        <td class="total_row {{ item.status_amount }}" align="right">{{ item.total_row | currency}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <br><br>
                <mat-card>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="col-md-6">
                        <label class="general-label-14">Observaciones del pedido</label>
                        <textarea  class="gui-textarea" value="{{ obs_order }}" id="comment" name="comment"
                          placeholder="Observaciones..." disabled></textarea>
                         
                          

                        <div *ngIf="NewOrderForm.controls.obs_order.invalid">
                          <mat-error *ngIf="NewOrderForm.controls.obs_order.errors?.required">Requerido</mat-error>
                          <mat-error *ngIf="NewOrderForm.controls.obs_order.errors?.minlength">Minimo 8 caracteres
                          </mat-error>
                        </div>
                        <br><br>

                        <label class="general-label-14">Orden de compra</label><br>
                        <input value="{{ num_order }}" type="text" class="gui-input" placeholder="Número de orden"
                          disabled>

                        <div *ngIf="NewOrderForm.controls.num_order.invalid">
                          <mat-error *ngIf="NewOrderForm.controls.num_order.errors?.required">Requerido</mat-error>
                          <mat-error *ngIf="NewOrderForm.controls.num_order.errors?.minlength">Minimo 8 caracteres
                          </mat-error>
                        </div>
                      </div>
                      <div *ngIf="customer" class="col-md-6">
                        <table class="table table-hover mbn" id="total-table">
                          <thead>
                            <tr>
                              <th colspan="2">Pago Neto</th>
                              <th colspan="2">Pago con PP (Pronto pago) {{ customer.PP | number : '1.2-2' }}%</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Seguro:</td>
                              <td><label *ngIf="customer.suma">{{ customer.seguro | currency }}</label></td>
                              <td>Seguro:</td>
                              <td><label *ngIf="customer.suma_pp">{{ customer.seguro_pp | currency  }}</label>
                              </td>
                            </tr>
                            <tr>
                              <td>Costo envio:</td>
                              <td>{{ customer.CostoEnvio | currency  }}</td>
                              <td>Costo envio:</td>
                              <td>{{ customer.envio_pp | currency  }}</td>
                            </tr>
                            <tr>
                              <td>Suma:</td>
                              <td>{{ customer.suma | currency  }}</td>
                              <td>Suma:</td>
                              <td>{{ customer.suma_pp | currency  }}</td>
                            </tr>
                            <tr>
                              <td>Subtotal:</td>
                              <td>{{ customer.subtotal | currency  }}</td>
                              <td>Subtotal:</td>
                              <td>{{ customer.subtotal_pp | currency  }}</td>
                            </tr>
                            <tr>
                              <td>IVA:</td>
                              <td>{{ customer.total_iva | currency  }}</td>
                              <td>IVA:</td>
                              <td>{{ customer.total_iva_pp | currency  }}</td>
                            </tr>
                            <tr>
                              <td>Total:</td>
                              <td>{{ customer.total | currency  }}</td>
                              <td>Total:</td>
                              <td>{{ customer.total_pp | currency  }}</td>
                            </tr>
                          </tbody>
                        </table>

                      </div>
                    </div>
                  </div>
                </mat-card>
                <br>

                <button (click)="new_order()" disabled="{{ process_order }}" type="button"
                  class="pull-right btn btn-primary"><i class="fa fa-arrow-circle-right"></i>Procesar pedido</button>
                <button type="button" class="pull-left btn btn-primary" mat-raised-button tabindex="-1"
                  (click)='moveToSelectedTab("Nuevo pedido")'><i class="fa fa-arrow-circle-left"></i> Regresar</button>
                <!-- <a class="pull-left btn btn-primary" [routerLink]="['/new-order/',10]" >
                        <i class="fa fa-arrow-circle-left"></i> Regresar
                      </a>
                       -->

              </div>



            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-tab>
  <!-- <mat-tab label="Third">Content 3</mat-tab> -->
</mat-tab-group>


