import { HttpClient, HttpErrorResponse, HttpHeaders , HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  static product: any;

  constructor(
    private http: HttpClient
    ) 
  { }

  public getPrecioMinimo(sede:number, transportista:number):Observable<any>{
    return this.http.get(`${environment.url_api}/precio-minimo/${sede}/${transportista}`).pipe(
      catchError((errors: HttpErrorResponse) => {
      console.log(JSON.stringify(errors))
      return throwError( errors );

    }));
  }

  public getAll(data: any):Observable<any> {
    
    return this.http.post(`${environment.url_api}/articulos/ampliado/`,data).pipe(
      catchError((errors: HttpErrorResponse) => {
      console.log(JSON.stringify(errors))
      return throwError( errors );

    }))
  }


  // public get(id: any):Observable<any> {
  //   return this.http.get(`http://localhost/dapesa_api/get_product.php`)
  // }

  public get(data: any):Observable<any> {
    // console.log(data)
    return this.http.post(`${environment.url_api}/articulos/detalle`,data)
  }

  public get_all_detail(data: any):Observable<any> {
    return this.http.post(`${environment.url_api}/articulos/porbloque`,data)
  }

  public images(prod: any):Observable<any> {
    return this.http.get(`${environment.url_api}/articulos/imagen/${prod.clave}`)
  }

  public price(data: any):Observable<any> {
    
    return this.http.post(`${environment.url_api}/articulos/precio`,data)
  }

  public price_bloque(requests_data:any){
    return this.http.post(`${environment.url_api}/articulos/precio/bloque`,requests_data)
  }

  public measurement_units(data: any):Observable<any> {
    return this.http.post(`${environment.url_api}/articulos/unidades`,data)
  }

  public equivalences(data: any):Observable<any> {
    return this.http.post(`${environment.url_api}/articulos/equivalencias`,data)
  }

  public delete(id: any):Observable<any> {
    return this.http.get(`${environment.url_api}/users.json`)
  }

  public static set_product(product: any): void{
    ProductsService.product = product;
  }

  public static get_product(): any{
    return ProductsService.product;
  }

  //Crear ir a traer articulos del carrito 
  public get_articulos_cart(data: any):Observable<any> {
    return this.http.post(`${environment.url_api}/articulos/all/details`,data)
  }

  public get_promocionales(data:any):Observable<any>{
    // const params = new HttpParams()
    //   .set('pMateriales', JSON.stringify(data.pMateriales))
    //   .set('pIDOrganizacionVentas', data.pIDOrganizacionVentas)
    //   .set('pIDCanalDistribucion', data.pIDCanalDistribucion)
    //   .set('pIDArea', data.pIDArea);

    // return this.http.get(`${environment.url_api}/obtener/promocionales?${params.toString()}`)
    return this.http.post(`${environment.url_api}/obtener/promocionales`, data);
  }

  public get_promocionales_ganados(data:any):Observable<any>{

    return this.http.post(`${environment.url_api}/obtener/promocionales/ganados`, data);
  }

  public get_promocionales_detalles (request:any):Observable<any>{
    return this.http.post(`${environment.url_api}/obtener/promocionales/ganados/data`, request);
  }
}
