
<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="nav sidebar-menu">
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle class="nav sub-nav">
    <li>
      <a mat-list-item matTreeNodeToggle [routerLink]="node.path">
        <mat-icon>{{node.iconname}}</mat-icon>
        <span class="sidebar-subtitle">{{node.name}}</span>
      </a> 
    </li>
  </mat-tree-node>

  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild" class="nav sub-nav">
    <li>
        <a mat-list-item matTreeNodeToggle>
          <mat-icon>{{node.iconname}}</mat-icon>
          <span class="sidebar-title">{{node.name}}</span>
          <mat-icon>{{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
        </a> 
      <ul [hidden]="!treeControl.isExpanded(node)">
        <ng-container matTreeNodeOutlet></ng-container>
      </ul>
    </li>
  </mat-nested-tree-node>
</mat-tree>
