<div class="page-title">
    <div class="row">
        <div class="col-md-12">
            <div class="col-md-6">
                <label *ngIf="!data.Existencia" class="general-label-14"><b>DETALLE ORDEN</b> </label>
                <label *ngIf="data.Existencia" class="general-label-14"><b>SELECCIONE UN CLIENTE</b> </label>
              </div>
              <div class="col-md-6">
                <a mat-dialog-close class="pull-right"><i class="fa fa-close"></i></a>
                <!-- <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button> -->
            </div>
        </div>
  
    </div>
  </div>

<mat-dialog-content *ngIf="!data.Existencia" class="mat-typography">
   
    <div class="col-md-12 gen-border">
        <!-- <label *ngIf="data.equivalences.length == 0"class="general-label">Sin equivalencias</label> -->
        <table *ngIf="data" class="table mbn" id="table-detail-order">
            <thead>
                <tr class="active">
                    <th colspan="4">Orden # {{ data.OrdenCompra }}</th>
                </tr>
              </thead>
            <tbody>
                <tr>
                    <td>Fecha de captura</td>
                    <td align="right">{{ data.FechaAlta }}</td>
                    <td>ID del cliente</td>
                    <td align="right">{{ data.IDCliente }}</td>
                </tr>
                <tr>
                    <td>Nombre del cliente</td>
                    <td align="right">{{ data.NombreCliente }}</td>
                    <td>Vendedor</td>
                    <td align="right">{{ data.Empleado }}</td>
                </tr>
                <tr>
                    <td>ID del Pedido</td>
                    <td align="right">{{ data.IDPedido }}</td>
                    <td>ID de Usuario</td>
                    <td align="right">{{ data.IDUsuario }}</td>
                </tr>  
                <tr>
                    <td>ID de Empleado</td>
                    <td align="right">{{ data.IDEmpleado }}</td>
                    <td>Empleado</td>
                    <td align="right">{{ data.Empleado }}</td>
                </tr> 
                <tr>
                    <td>Prioridad de Entrega</td>
                    <td align="right">{{ data.IDPrioridadEntrega }}</td>
                    <td>Canal de Venta</td>
                    <td align="right">{{ data.IDCanalVenta }}</td>
                </tr> 
                <tr>
                    <td>Organización de Ventas</td>
                    <td align="right">{{ data.IDOrganizacionVentas }}</td>
                    <td>Moneda</td>
                    <td align="right">{{ data.IDMoneda }}</td>
                </tr> 
                <tr>
                    <td>Pronto Pago</td>
                    <td align="right">{{ data.ProntoPago | number : '1.2-2'}}</td>
                    <td>Seguro</td>
                    <td align="right">{{ data.Seguro | number : '1.2-2'}}</td>
                </tr> 
                 
                <tr>
                    <td>Transportista</td>
                    <td align="right">{{ data.Transportista }}</td>
                    <td>Municipio</td>
                    <td align="right">{{ data.Municipio }}</td>
                    
                </tr> 
                <tr>
                    <td>País</td>
                    <td align="right">{{ data.IDPais }}</td>
                    <td>Estado</td>
                    <td align="right">{{ data.IDEstado }}</td>
                </tr> 
                <tr>
                    <td>Código Postal</td>
                    <td align="right">{{ data.CodigoPostal }}</td>
                    <td>Colonia</td>
                    <td align="right">{{ data.Colonia }}</td>
                </tr>
                <tr>
                    <td>Calle</td>
                    <td align="right">{{ data.Calle }}</td>
                    <td>Número</td>
                    <td align="right">{{ data.Numero }}</td>
                </tr>
                <tr>
                    <td>Teléfono</td>
                    <td align="right">{{ data.Telefono1 }}</td>
                    <td>Estatus en SAP</td>
                    <td align="right">{{ data.Estatus }}</td>   
                </tr>
                <tr>
                    <td>Mostrador</td>
                    <td align="right">{{ data.AMostrador }}</td>
                    <td>Ruta</td>
                    <td align="right">{{ customer.Ruta }}</td>
                </tr>



                <tr class="active">
                    <td colspan="4" align="center">Totales</td>
                </tr>
                <tr>
                    <td>Suma</td>
                    <td align="right">{{ data.TuPrecioSuma | number : '1.2-2'}}</td>
                    <td>Seguro</td>
                    <td align="right">{{ data.TuPrecioSeguro | number : '1.2-2'}}</td>
                </tr>
                <tr>
                    <td>Costo de Envío</td>
                    <td align="right">{{ data.CostoEnvio | number : '1.2-2'}}</td>
                    <td>Subtotal</td>
                    <td align="right">{{ data.TuPrecioSubtotal | number : '1.2-2'}}</td>
                    
                </tr>
                <tr>
                    <td>Iva</td>
                    <td align="right">{{ data.TuPrecioIva | number : '1.2-2'}}</td>
                    <td>Total con IVA</td>
                    <td align="right">{{ data.TuPrecioTotal | number : '1.2-2' }}</td>
                </tr>
                 
                <tr>
                    <td colspan="2" >Observaciones</td>
                    <td colspan="2" class="limitada" align="right">{{ data.Observaciones }}</td>
                </tr> 
             
                   
            </tbody>
          </table>
    </div>
</mat-dialog-content>




<!-- COPY -->

<mat-dialog-content *ngIf="data.Existencia" class="mat-typography">
    <div class="col-md-12 gen-border">
        <!-- <label *ngIf="data.equivalences.length == 0"class="general-label">Sin equivalencias</label> -->
        <table *ngIf="data" class="table table-hover mbn" id="table-detail-order">
            <thead>
                <tr class="active">
                    <th colspan="2">Orden # {{ data.IDPedido }}</th>
                </tr>
              </thead>
            <tbody>
               
                <tr>
                    <td>Cliente</td>
                    <td><ng-select2 [(value)]="customer_id" id="select2-customer" class="js-example-placeholder-multiple" [data]="customers" [placeholder]="'Seleccione una opción...'" [allowClear]="true" (valueChanged)="get_order_detail($event, data.IDPedido)" width="100%" height="10px;" style="width: 100%;"></ng-select2> </td>
                </tr> 
                <tr>
                    <td></td>
                    <td><a mat-dialog-close class="btn btn-primary" (click)="send_copy_data(customer_id)">
                        <i class="icon-pencil-alt"></i> Crear pedido
                      </a>
                      
                    </td>
                </tr>
                   
            </tbody>
          </table>
    </div>   
</mat-dialog-content>