export const environment = {
  production: false,
  // url_api: 'http://dapesaqa.engranedigital.com/api',
  // url_api: 'http://localhost:8000/api',
  // url_api: 'http://192.168.0.249:8000/api',
  //  url_api_aws: 'https://zkmn0e2csk.execute-api.us-east-1.amazonaws.com/sandbox',
  // url_api: 'http://backclon.dapesa.com/api',
  // url_api: 'https://back.dapesa.com/api',
  // url_api: 'http://34.201.5.78/api',
  url_api: 'https://demo.dapesa.com/api',
  // firebase: {
  //   apiKey: "AIzaSyAtnAZ6h2CTG7K4GksEoiNbr9949QFIvi0",
  //   authDomain: "rackup-87c8b.firebaseapp.com",
  //   projectId: "rackup-87c8b",
  //   storageBucket: "rackup-87c8b.appspot.com",
  //   messagingSenderId: "4696952544",
  //   appId: "1:4696952544:web:702c062f914edbbd844a85",
  //   measurementId: "G-EJ7F2SN5HM"
  // }
};
