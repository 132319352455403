<div class="example-container" [class.example-is-mobile]="mobileQuery.matches" *ngIf="shouldRun">
  <mat-toolbar class="example-toolbar navbar navbar-fixed-top navbar-shadow">
    <div class="navbar-branding">

      <!--<a class="navbar-brand" mat-icon-button (click)="snav.toggle()"><img src="assets/img/logos/dapesa-logo-white.png" title="Dapesa" class="img-responsive w160"><span id="toggle_sidemenu_l" class="ad ad-lines"></span></a>-->

      <div class="row">
        <div class="col">
          <img style="margin-top:-8px;" src="assets/img/logos/dapesa-logo-white.png" title="Dapesa" class="img-responsive w160" />      

        </div>
        <div class="col">
          <a style="margin-top:9px; color: white; cursor: pointer" class="navbar-brand" mat-icon-button (click)="snav.toggle()">
            <!--<span id="toggle_sidemenu_l" class="ad ad-lines"></span>-->
            <mat-icon>menu</mat-icon>
          </a>  
        </div>
      </div>      

    </div>

    <mat-accordion class="navbar-right">
      <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-description>
            <img src="assets/img/avatars/placeholder.png" alt="avatar" class="mw30 br64">
            <span class="hidden-xs pl15"> {{ detail_user.usuario }}</span>
            <span *ngIf="detail_user" class="hidden-xs pl15"> {{ detail_user.tipo }}</span>
            <mat-icon>arrow_drop_down</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <!-- <a href="#" class="">
          <span class="fa fa-power-off pr5"></span> Logout </a> -->
          <a (click)="logout()" class="">
          <span class="hidden-xs pl15"> Cerrar sesion</span>
          <mat-icon>exit_to_app</mat-icon></a>
      </mat-expansion-panel>
    </mat-accordion>
    </mat-toolbar>
  
    <mat-sidenav-container class="example-sidenav-container"
                           [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
      <mat-sidenav #snav [mode]="mobileQuery.matches ? 'over' : 'side'"
                   [fixedInViewport]="mobileQuery.matches" fixedTopGap="0" opened>
        <!-- Sidebar Widget - Author -->
        <div class="sidebar-widget author-widget">
          <div class="media">
            <a class="media-left" href="#">
              <!-- <img src="assets/img/avatars/placeholder.png" class="img-responsive"> -->
            </a>
            <div class="media-body">
              <div class="media-links">
                <!-- <a href="#" class="sidebar-menu-toggle">User Menu -</a> <a href="pages_login(alt).html">Logout</a> -->
              </div>
              <!-- <div class="media-author">{{ detail_user.nombre }}</div> -->
            </div>
          </div>
        </div>

        
        <mat-nav-list>
          <app-tree></app-tree>
        </mat-nav-list>

        <!-- Start: Sidebar Collapse Button -->
	      <!--<div class="sidebar-toggle-mini">
	        <a (click)="snav.toggle()">
	          <span class="fa fa-sign-out"></span>
	        </a>
	      </div>-->
	      <!-- End: Sidebar Collapse Button -->
      </mat-sidenav>
  
      <mat-sidenav-content>
        <router-outlet></router-outlet>
      </mat-sidenav-content>

    </mat-sidenav-container>
  </div>
  

