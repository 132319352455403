import { Component, Input, OnInit } from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { of } from 'rxjs';
import { LoginService } from 'src/app/services/login.service';

export interface TreeData {
  name: string;
  iconname?: string;
  path: string;
  children?: TreeData[];
}

const TREE_DATA: TreeData[] = [
  {
    name: 'Clientes',
    iconname: 'list',
    path: '/customers',
  },
  {
    name: 'Crear Pedido',
    iconname: 'add_shopping_cart',
    path: '/new-order',
    // children: [
    //   {name: 'Sub1', iconname: 'list'},
    //   {name: 'Sub2', iconname: 'check'},
    //   {name: 'Sub3', iconname: 'folder'},
    // ]
  }, {
    name: 'Lista de pedidos',
    iconname: 'description',
    path: '/all-orders',
    // children: [
    //   {
    //     name: 'Green',
    //     iconname: 'list',
    //     children: [
    //       {name: 'Broccoli'},
    //       {name: 'Brussels sprouts'},
    //     ]
    //   }, {
    //     name: 'Orange',
    //     iconname: 'book',
    //     children: [
    //       {name: 'Pumpkins'},
    //       {name: 'Carrots'},
    //     ]
    //   },
    // ]
  },
];

@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.css']
})
export class TreeComponent implements OnInit {
  dataSource = new MatTreeNestedDataSource<TreeData>();
  treeControl = new NestedTreeControl<TreeData>(node => node.children);
  public user: any;

  hasChild(_: number, node: TreeData) {
    console.log(node);
    return node.children != null && node.children.length > 0;
  }

  constructor() { 
    this.dataSource.data = TREE_DATA;
    this.user = LoginService.get_user();
  }

  ngOnInit(): void {
  }

}
