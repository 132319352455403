import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { AccountComponent } from './account/account.component';
import { AllProductsComponent } from './all-products/all-products.component';
import { SidenavComponent } from '../shared/sidenav/sidenav.component';
import { NewOrderComponent } from './new-order/new-order.component';
import { CustomersComponent } from './customers/customers.component';
import { AllOrdersComponent } from './all-orders/all-orders.component';
import { AuthorizationRulesGuard } from '../services/authorization-rules.guard';
import { isDevMode } from '@angular/core';


const contentRoutes: Routes = [
  // { path: 'home', component: HomeComponent },
  {
    path: 'home', component: SidenavComponent,
    children: [
      {
        path: '',
        component: HomeComponent,
      },
    ],
  },
  { path: 'login', component: LoginComponent },
  { path: 'account', component: AccountComponent },
  { path: 'all-products', component: SidenavComponent,
    canActivate: [AuthorizationRulesGuard],
    children: [
      {
        path: '',
        component: AllProductsComponent,
      },
    ],
  },
  { path: 'customers', component: SidenavComponent,
    canActivate: [AuthorizationRulesGuard],
    children: [
      {
        path: '',
        component: CustomersComponent,
      },
    ],
  },
  { path: 'all-orders', component: SidenavComponent,
    canActivate: [AuthorizationRulesGuard],
    children: [
      {
        path: '',
        component: AllOrdersComponent,
      },
    ],
  },
  { path: 'new-order/:id', component: SidenavComponent,
    canActivate: [AuthorizationRulesGuard],
    children: [
      {
        path: '',
        component: NewOrderComponent
      },
    ],
  },
  { path: 'new-order', component: SidenavComponent,
    canActivate: [AuthorizationRulesGuard],
    children: [
      {
        path: '',
        component: NewOrderComponent,
      },
    ],
  },
];

// if(isDevMode()) {
//   contentRoutes.push({ path: 'new-order/:id', component: NewOrderComponent,
//     children: [
//       {
//         path: '',
//         component: NewOrderComponent,
//       },
//     ],
//   })
// }


@NgModule({
    imports: [ RouterModule.forChild(contentRoutes)],
    exports: [ RouterModule ]
  })


export class ContentRoutes { }