import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {
  static rows: any;
  static all_products: any;
  static customer_order: any;
  static customer_id: any;

  constructor(
    private http: HttpClient
  ) 
  { 
    
  }

  public getAll(data: any):Observable<any> {
    return this.http.post(`${environment.url_api}/pedidos/obtener
    `,data)
  }

  public get(id_order: any):Observable<any> {
    return this.http.get(`${environment.url_api}/pedidos/detalles/${id_order}`)
  }

  public new(data: any):Observable<any> {
    return this.http.post(`${environment.url_api}/pedidos/grabar
    `,data)
  }

  public insert_art(data: any):Observable<any> {
    return this.http.post(`${environment.url_api}/pedidos/detalle/insertar`,data)
  }
  

  public insert_art_bloque(data: any):Observable<any> {
    return this.http.post(`${environment.url_api}/pedidos/detalle/insertar/porbloque`,data)
  }

  public delete_art(data: any):Observable<any> {
    return this.http.post(`${environment.url_api}/pedidos/detalle/borrar`, data)
  }

  public delete_all_art(data: any){
    return this.http.post(`${environment.url_api}/pedidos/detalle/borrar/completo`, data)
  }
  

  public delete_art_bloque(data: any):Observable<any> {
    return this.http.post(`${environment.url_api}/pedidos/detalle/borrar/porbloque`, data)
  }
  
  public update_art(data: any):Observable<any> {
    return this.http.post(`${environment.url_api}/pedidos/detalle/actualizar`,data)
  }

  public update_art_bloque(data: any):Observable<any> {
    return this.http.post(`${environment.url_api}/pedidos/detalle/actualizar/porbloque`,data)
  }

  public get_temporal(id_usuario: any, id_cliente: any):Observable<any> {
    return this.http.get(`${environment.url_api}/pedidos/detalle/temporal/${id_usuario}/${id_cliente}`)
  }

  public validate_temp(data: any):Observable<any> {
    return this.http.post(`${environment.url_api}/articulos/precio/verificar`,data)
  }
  

  public download_file(data: any):Observable<any> {
    return this.http.post(`${environment.url_api}/pedidos/reporte`,data, {responseType:'blob' as 'json'})
  }

  public static set_rows(rows: any): void{
    OrdersService.rows = rows;
  }

  public static get_rows(): any{
    return OrdersService.rows;
  }

  public static set_products(products: any): void{
    OrdersService.all_products = products;
  }

  public static get_products(): any{
    return OrdersService.all_products;
  }

  public static set_customer_order(customer: any): void{
    OrdersService.customer_order = customer;
  }

  public static get_customer_order(): any{
    return OrdersService.customer_order;
  }

  public static set_customer_id(customer: any): void{
    OrdersService.customer_id = customer;
  }

  public static get_customer_id(): any{
    return OrdersService.customer_id;
  }
}
